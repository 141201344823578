<template>
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      max-width="500px"

    >
      <v-card>
        <v-card-text style="height: 370px; overflow-y:hidden;">
          <div class="ma-6 pa-0 text-center">
            <img
              src="@/assets/img/5910366.png"
              width="150px"
              height="150px"
              alt="One Box"
            />
          </div>
          <div class="text-center">
            <h2>{{ $t("conditions") }}</h2>
          </div>
          <div class="text-center">
            <br />
            <span style="font-size: 20px;">
                {{ $t("textconditions_delete_filter") }}
                <!-- เอกสารลงวันที่ วันที่ส่งเอกสาร หรือ เลขที่เอกสาร อย่างใดอย่างหนึ่ง เพื่อกรองเอกสารที่ต้องการลบจาก Filter -->
            </span>
          </div>
          <br />
          <div class="text-center">

          <v-btn color="red" outlined @click="$emit('closedialog')">
            {{$t("changeName.close")
          }}</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import VueCookies from "vue-cookies";
import { mapState, mapGetters } from "vuex";
export default {
    props: ["show"],
    data: function (){
        return {
            checkshow:false,
            dialog:false,
        };

    },
    computed:{
        ...mapState(["username", "authorize", "account_active", "color"]),
        ...mapState({ processloader: "loading" }),
        ...mapGetters([
            "dataUsername",
            "dataAuthorize",
            "dataAccountActive",
            "dataAccesstoken",
            "dataBusinessProfile",
            "dataCitizenProfile",
            "dataDepartmentAccessId",
            "dataAccountId",
        ]),
        shower: {
            get() {
                if (this.show === true) {
                }
                return this.show;
            },
        },
        resolutionScreen() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return 220;
                case "sm":
                    return 400;
                case "md":
                    return 500;
                case "lg":
                    return 600;
                case "xl":
                    return 800;
            }
        },
    },
    methods:{
    checkdialog() {
       VueCookies.set('checkalertcondition', false);
       this.$emit("closedialog")
    },
    }

};
</script>

<style>

</style>